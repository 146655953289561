<script setup lang="ts">
import { AsfChevronProps } from '@ui/types'

const props = withDefaults(defineProps<AsfChevronProps>(), { appearance: 'bottom' })
const modifier = computed(() => `m-${props.appearance}`)
</script>
<template>
  <span :class="['asf-chevron', modifier]">
    <slot>
      <span class="asf-chevron__bar m-left" />
      <span class="asf-chevron__bar m-right" />
    </slot>
  </span>
</template>
<style lang="postcss">
@import '@components/atoms/Chevron/Chevron.css';
</style>
